<template>
  <iframe ref="iframe" class="reward" :src="rewardUrl" sandbox="allow-scripts allow-top-navigation allow-modals allow-popups allow-popups-to-escape-sandbox allow-forms allow-downloads"></iframe>
</template>

<script>
export default {
  name: "Reward",
  props: {
    lang: {
      type: String,
    },
    sid: {
      type: Number,
    },
    rid: {
      type: String,
    },
    uuid: {
      type: String,
    },
    result: {
      type: Object,
      default: () => ({})
    },
    script: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      reward: "",
    };
  },
  computed: {
    rewardUrl() {
      return `${process.env.VUE_APP_ADMIN_API}/api/reward-render?sid=${this.sid}&rid=${this.rid}&uuid=${this.uuid}&lang=${this.lang}`;
    },
  },
  methods: {
    /* eslint-disable no-unused-vars */
    async preload (...args) {
      try {
        if (!this?.script) return;
        const functionString = this.script;

        return await (async(args) => {
          const self = args[0]
          const result = args[1]
          return eval("(async () => {" + functionString + "\n})()")
        })(args);

      } catch (error) {
        console.log(error);
      }
    }
    /* eslint-enable no-unused-vars */
  },
  async mounted() {
    const frame = this.$refs.iframe;
    document.documentElement.style["overflow-y"] = "hidden";
    frame.contentDocument.documentElement.innerHTML = this.reward;
    frame.contentDocument.documentElement.style["overflow-x"] = "hidden";
    this.$emit('ready', true)
  },
  beforeDestroy() {
    document.documentElement.style["overflow-y"] = "";
  },
  async beforeMount (){
    await new Promise(r => setTimeout(r, 250))
    await this.preload(this, this.result)
    await new Promise(r => setTimeout(r, 150))
  }
};
</script>

<style scoped>
.reward {
  z-index: 10;
  width: 100vw;
  height: 100vh;
}
</style>
